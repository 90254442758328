<template>
<div class="course">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="course-nr">
    <div class="train-tab">
      <div class="left">
        <p :class="curTab.id == item.id ? 'active' : ''" v-for="item in tabList" :key="item.id" @click="changeTab(item)">{{ item.name }}</p>
      </div>
      <el-button type="primary" size="small" style="margin-bottom: 5px;" @click="drawerShow=true">+新增课程</el-button>
    </div>
    <CourseList v-show="curTab.id === '1'"></CourseList>
    <MyCourse  v-show="curTab.id==='2'" :refresh="refresh"/>
    <Category v-show="curTab.id==='3'" @refresh="getCategory()"/>
    <!-- <Task v-show="curTab.id == '2'"></Task> -->
  </div>

  <el-drawer :visible.sync="drawerShow" :size="1200">
    <div class="drawer-title" slot="title">
      <div class="drawer-title-text">添加课程</div>
      <div class="drawer-title-btns">
        <Button class="mr10" size="large">取消</Button>
        <Button type="primary" size="large" @click="confirm()">确定</Button>
      </div>
    </div>
    <div style="padding: 20px;">
      <div class="course-create-nr">
        <el-form ref="formValidateref" :model="formValidate" :rules="ruleValidate" label-width="110px">
          <div class="distinguish" v-if="!isPrivate">
            <div class="distinguish-color"></div>
            <div class="distinguish-text">可编辑</div>
          </div>
          <div class="distinguish" v-if="!isPrivate">
            <div class="distinguish-color back-red"></div>
            <div class="distinguish-text">不可编辑</div>
          </div>
          <el-form-item label="方向" prop="category_id">
            <el-select v-model="formValidate.category_id" v-if="isPrivate"
              placeholder="请选择方向" class="w300">
              <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
            <el-input v-else v-model="categoryName" disabled size="large" placeholder="请输入名称" class="w300"></el-input>
            <span v-if="isPrivate" style="padding-left: 20px">选择课程所属分类，还没有分类？<a @click="goCategory"
                style="color: #4a79ff">点击去创建</a></span>
          </el-form-item>
          <el-form-item label="名称" prop="name">
            <el-input v-model="formValidate.name" maxlength="20" :disabled="!isPrivate" size="large" placeholder="请输入名称"
              class="w300"></el-input>
          </el-form-item>
          <el-form-item label="等级" prop="lesson_level">
            <el-select v-model="formValidate.lesson_level" :disabled="!isPrivate" placeholder="请选择等级" class="w300">
              <el-option v-for="item in levelList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="课时数" prop="lesson_count">
            <el-input-number :disabled="!isPrivate" v-model="formValidate.lesson_count" size="large"
              placeholder="请输入课时数" style="width: 180px"></el-input-number>
          </el-form-item>
          <!-- <el-form-item label="周课数">
            <el-input-number type="number" :disabled="!isPrivate" v-model="formValidate.lesson_week_count" size="large"
              placeholder="请输入周课数" style="width: 180px"></el-input-number>
          </el-form-item> -->
          <el-form-item style="width: 50%" label="课程描述" prop="content">
            <el-input type="textarea" :disabled="!isPrivate" v-model="formValidate.content" :rows="5" style="width: 70%"
              size="large" placeholder="请输入课程描述"></el-input>
          </el-form-item>
          <el-form-item label="封面图" prop="uploadImg" class="w300">
            <el-upload class="avatar-uploader " :action="constant.URL + '/uploadfile/upload'" :data="{
              type: 3,
            }" accept=".jpg,.png,.jpeg,.gif" :headers="{
'access-token': Cookies.get(constant.tokenName),
}" :on-success="uploadFileSuccess" :show-file-list="false" :disabled="!isPrivate">
              <div class="upload-btn" v-if="!formValidate.uploadImg">
                <Icon type="ios-add" :size="50" />
              </div>
              <img :src="formValidate.uploadImg" width="120" v-else />
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </el-drawer>

</div>
</template>

<script>
import CourseList from './components/list/courseList';
// import Task from './components/list/task'
import MyCourse from "./components/myCourse.vue"
import Category from "./components/courseCategory.vue"
import Cookies from "js-cookie";
export default {
  name: "list",
  data(){
    return{
      Cookies,
      drawerShow:false,
      isPrivate:true,
      formValidate: {
        name: "",
        uploadfile_id: "",
        lesson_level: "",
        lesson_count: "",
        lesson_week_count: '',
        group_ids: [],
        category_id: "",
        content: "",
        uploadImg: "",
      },
      categoryName: "",
      categoryList: [],
      levelList: [],
      ruleValidate: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        category_id: [
          { required: true, message: "请选择方向", trigger: "change" },
        ],
        lesson_level: [
          { required: true, message: "请输入等级", trigger: "blur" },
        ],
        group_ids: [
          { required: true, message: "请选择班级", trigger: "change" },
        ],
        lesson_count: [
          { required: true, message: "请输入课时数", trigger: "blur" },
        ],
        uploadImg: [
          { required: true, message: "请上传封面", trigger: "change" },
        ],
        content: [
          { required: true, message: "请输入课程描述", trigger: "blur" },
        ],
      },
      refresh:0,
      tabList:[
        {
          name:'课程列表',
          id:'1',
          tab:'list'
        },
        {
          name:"课程管理",
          id:"2",
          tab:"myCourse"
        },
        {
          name:"课程分类",
          id:"3",
          tab:"courseCategory"
        }
        // {
        //   name:'课程任务记录',
        //   id:'2',
        //   tab:'task'
        // }
      ],
      curTab:{
        name:'课程列表',
        id:'1',
        tab:'list'
      },
    }
  },
  components:{
    CourseList,
    // Task,
    MyCourse,
    Category
  },
  created(){
    let tab = this.$route.query.tab || '';

    if(tab){
      this.curTab = this.tabList.filter((item)=>{
        return item.tab == tab;
      })[0];
    }
    this.getForm();
    this.getCategory();
  },
  watch:{
    '$route.query.tab':{
      handler(val){
        if(val){
          this.curTab = this.tabList.filter((item)=>{
            return item.tab == val;
          })[0];
        }
      }
    }
  },
  methods:{
    confirm() {
      this.$refs["formValidateref"].validate((valid) => {
        if (valid) {
          let params = {
            name: this.formValidate.name,
            lesson_level: this.formValidate.lesson_level,
            lesson_count: this.formValidate.lesson_count,
            category_id: this.formValidate.category_id,
            group_ids: this.formValidate.group_ids,
            uploadfile_id: this.formValidate.uploadfile_id,
            content: this.formValidate.content,
            lesson_week_count: this.formValidate.lesson_week_count
          };
          this.api.course.courseCreate(params).then(() => {
            this.$Message.success("操作成功");
            this.drawerShow = false;
            this.refresh=Math.random();
            this.curTab={
              name:"我创建的课程",
              id:"2",
              tab:"myCourse"
            };
          });
        }
      });
    },
    getForm() {
      this.api.course.courseForm().then((res) => {
        this.levelList = [];
        for (let name in res.lesson_levels) {
          this.levelList.push({
            name: res.lesson_levels[name],
            id: name,
          });
        }
      });
    },
    getCategory() {
      this.api.course.selfThirdList({
        user_id:'-1'
      }).then((res) => {
        this.categoryList = res.list;
      });
    },
    goCategory() {
      this.drawerShow=false;
      this.curTab={
        name:"课程分类",
        id:"3",
        tab:"courseCategory"
      }
    },
    uploadFileSuccess(response) {
      //上传文件成功
      this.formValidate.uploadfile_id = response.data.info.id;
      this.formValidate.uploadImg = response.data.info.upload_path;
      this.$refs.formValidateref.validateField("uploadfile_id");
    },
    changeTab(data){
      this.curTab = data;
      this.$router.push({
        path:'/course/teacher-list',
        query:{
          tab:this.curTab.tab
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
.course{
  margin: 20px;
  .course-nr{
    padding: 20px;
    background-color: #FFFFFF;
    font-size: 14px;

    .train-tab{
      margin-bottom: 20px;

      border-bottom: 1px solid #EEEEEE;
      font-size: 16px;
      color: #666666;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left{
        display: flex;
        >p{
          padding-bottom: 10px;
          margin-right: 30px;
          cursor: pointer;
        }
      }
      .active{
        font-weight: bold;
        color: #4A79FF;
        border-bottom: 4px solid #5782FF;
      }
    }

  }
  ::v-deep .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
.course-create-nr{
  .distinguish{
    display: flex;
    align-items: center;
    margin: 20px 0;
    .distinguish-color{
      height: 10px;
      width: 20px;
      background-color: #5477F7;
      margin-right: 10px;
      margin-left: 10px;
    }
    .back-red{
      background-color: #DF4C54;
    }
    .distinguish-text{
      font-size: 18px;
      font-weight: bold;
    }
  }
}
</style>

