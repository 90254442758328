<template>
  <Modal
      v-model="status"
      :title="tit"
      @on-cancel="cancel"
      :z-index="2002"
  >
    <p>{{txt}}</p>
    <div slot="footer">
      <Button @click="cancel">{{$t('project_cancel')}}</Button>
      <Button type="error" @click="confirm" :loading="loading">{{btnTxt || $t('project_delete')}}</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  name: "deleteModal",
  props:{
    status:{
      default:false,
      type:Boolean
    },
    txt:{
      default:'',
      type:String
    },
    loading:{
      default:false,
      type:Boolean
    },
    btnTxt:{
      default:'',
      type:String
    },
    tit:{
      default:'确定删除吗？',
      type:String
    }
  },
  methods:{
    cancel(){
      this.$emit('cancel');
    },
    confirm(){
      this.$emit('confirm');
    }
  }
}
</script>

<style scoped>

</style>
