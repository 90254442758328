<template>
  <div>
    <div class="role-main">
      <div class="top">
        <div>
          <Button type="primary" @click="add" style="margin-right: 10px;">添加分类</Button>
          <!-- <Button type="primary" @click="toCategoryVerify" style="margin-right: 10px;">分类审核</Button>
          <Button type="info" @click="toAiGenerateList">AI生成考点</Button> -->
        </div>
        <div>
          <Input v-model="keyword"
                 :placeholder="$t('common_search_keyword')"
                 clearable
                 search
                 :enter-button="true"
                 @on-enter="changeKeyword"
                 @on-search="changeKeyword"
                 style="width: 200px"
          />
        </div>
      </div>
      <div class="search">
        <div class="search-item">
          <div class="name">
            一级分类:
          </div>
          <div class="cont">
            <div class="values">
              <div :class="curCategoryId == item.id ? 'active' : ''" v-for="item in categoryList" :key="item.id + 'category'" @click="changeSearch(item,'curCategoryId')">{{item.name}}</div>
            </div>
          </div>
        </div>
        <div class="search-item">
          <div class="name">
            二级分类:
          </div>
          <div class="cont">
            <div class="values" >
              <div :class="cursecondCategoryId == item.id ? 'active' : ''" v-for="item in secondCategoryList" :key="item.id + 'secondCategory'" @click="changeSearch(item,'cursecondCategoryId')">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
      <Table border :columns="columns" :loading="loading" :data="data"></Table>
      <!-- <div class="page">
        <Page :total="total" @on-change="changePage" :current="page" @on-page-size-change="changePageSize" show-total show-sizer show-elevator></Page>
      </div> -->
      <DeleModal :status="deleModalStatus" :txt="$t('dataset_category_dele_tip')" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>

      <!-- <Modal
          v-model="modalStatus"
          :title="modalTit"
          @on-cancel="cancel"
      >
        <Form :model="formItem" ref="formCustom" :label-width="80" :rules="ruleValidate">
          <FormItem label="一级分类" prop="name" v-if="type != 'edit'">
            <Select v-model="formItem.firstCategoryId" style="width:200px" @on-change="changeFirstCategory">
              <Option v-for="item in formCategoryList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="二级分类" prop="name" v-if="formItem.firstCategoryId && type != 'edit'">
            <Select v-model="formItem.parentId" style="width:200px">
              <Option v-for="item in formSecondCategoryList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem :label="$t('dataset_category_table_name')" prop="name">
            <el-input v-model="formItem.name" maxlength="20" size="small" :placeholder="$t('dataset_category_add_name_tip')"></el-input>
          </FormItem>
          <FormItem label="状态" prop="statusId">
            <Select v-model="formItem.statusId" style="width:200px">
              <Option v-for="item in statusList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="排序">
            <Input v-model="formItem.sort" placeholder="请输入"></Input>
          </FormItem>
          <FormItem :label="$t('dataset_category_table_desc')" prop="desc">
            <Input v-model="formItem.desc" :placeholder="$t('dataset_category_add_desc_tip')"></Input>
          </FormItem>
          <FormItem label="图谱ID" prop="mapId">
            <Input v-model="formItem.mapId" placeholder="请输入图谱id"></Input>
          </FormItem>
        </Form>
        <div slot="footer">
          <Button @click="cancel">{{ $t('dataset_cancel') }}</Button>
          <Button type="primary" @click="ok" :loading="addBtnLoading" v-if="type != 'check'" >{{ $t('dataset_confirm') }}</Button>
        </div>
      </Modal> -->
      <!-- <Modal
          v-model="addDataModal"
          title="单题添加"
          width="70%"
      >
        <div>
          <AddData v-if="addDataModal" :addDataModal="addDataModal"  @changeData="changeData"></AddData>
        </div>

        <div slot="footer">
          <Button @click="addDataModal=false">{{ $t('trainingcamp_train_feedback_cancel') }}</Button>
          <Button type="primary" @click="confirmCorrection">{{ $t('trainingcamp_train_feedback_confirm') }}</Button>
        </div>
      </Modal> -->
      <TrainSeting :status="trainSetting" @close="closeTrainSetting" @confirm="confirmBrushConfig"></TrainSeting>
    </div>

    <!-- 添加分类 -->
    <el-drawer :visible.sync="modalStatus" :size="800" @close="cancel">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">{{ modalTit }}</div>
        <div class="drawer-title-btns">
          <Button @click="cancel" style="margin-right: 10px;">{{ $t('dataset_cancel') }}</Button>
          <Button type="primary" @click="ok" :loading="addBtnLoading" v-if="type != 'check'" >{{ $t('dataset_confirm') }}</Button>
        </div>
      </div>
      <div style="padding: 20px;">
        <Form :model="formItem" ref="formCustom" :label-width="80" :rules="ruleValidate">
          <FormItem label="一级分类" prop="name" v-if="type != 'edit'">
            <Select v-model="formItem.firstCategoryId" style="width:200px" @on-change="changeFirstCategory">
              <Option v-for="item in formCategoryList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="二级分类" prop="name" v-if="formItem.firstCategoryId && type != 'edit'">
            <Select v-model="formItem.parentId" style="width:200px">
              <Option v-for="item in formSecondCategoryList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem :label="$t('dataset_category_table_name')" prop="name">
            <el-input v-model="formItem.name" maxlength="20" size="small" :placeholder="$t('dataset_category_add_name_tip')"></el-input>
          </FormItem>
<!--          <FormItem label="父级" v-if="formItem.parentId != 0">-->
<!--            <p>{{formItem.parentName}}({{formItem.parentId}})</p>-->
<!--          </FormItem>-->
          <FormItem label="状态" prop="statusId">
            <Select v-model="formItem.statusId" style="width:200px">
              <Option v-for="item in statusList" :value="item.id" :key="item.id">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="排序">
            <Input v-model="formItem.sort" placeholder="请输入"></Input>
          </FormItem>
          <FormItem :label="$t('dataset_category_table_desc')" prop="desc">
            <Input v-model="formItem.desc" :placeholder="$t('dataset_category_add_desc_tip')"></Input>
          </FormItem>
          <FormItem label="图谱ID" prop="mapId">
            <Input v-model="formItem.mapId" placeholder="请输入图谱id"></Input>
          </FormItem>
<!--          <FormItem label="自动开通" prop="autoOpen">-->
<!--            <RadioGroup v-model="formItem.autoOpen">-->
<!--              <Radio :label="index" v-for="(item,index) in autoOpenObj" :key="index">{{item}}</Radio>-->
<!--            </RadioGroup>-->
<!--          </FormItem>-->
<!--          <FormItem label="免费刷题" prop=" isfree">-->
<!--            <RadioGroup v-model="formItem.isfree">-->
<!--              <Radio :label="index" v-for="(item,index) in isfreeObj" :key="index">{{item}}</Radio>-->
<!--            </RadioGroup>-->
<!--          </FormItem>-->
        </Form>
      </div>
    </el-drawer>

    <!--  -->
    <el-drawer :visible.sync="addDataModal" :size="1200" @close="cancel">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">单题添加</div>
        <div class="drawer-title-btns">
          <Button @click="addDataModal=false" style="margin-right: 10px;">{{ $t('trainingcamp_train_feedback_cancel') }}</Button>
          <Button type="primary" @click="confirmCorrection">{{ $t('trainingcamp_train_feedback_confirm') }}</Button>
        </div>
      </div>
      <div style="padding: 20px;">
        <AddData v-if="addDataModal" :addDataModal="addDataModal"  @changeData="changeData"></AddData>
      </div>
    </el-drawer>
  </div>

</template>

<script>
import DeleModal from '../../../components/deleteModal.vue';
import util from '@/utils/tools.js';
import TrainSeting from '../../../components/trainSetting';
import AddData from './addData.vue';
export default {
  name: "role",
  data(){
    // const validateMapId = (rule, value, callback) => {
    //   console.log(this.formItem.type,'this.formItem.typethis.formItem.type')
    //   if (!value && this.formItem.type == 1) {
    //     return callback(new Error('请输入教材ID'));
    //   }
    //   callback();
    // };

    const validateIssfrees = (rule, value, callback) => {
      if (!value  && this.formItem.type == 1) {
        return callback(new Error(this.$t('请选择是否免费')));
      }
      callback();
    };


    return{
      columns: [
        {
          title: this.$t('dataset_category_table_index'),
          key:'id',
        },
        {
          title: this.$t('dataset_category_table_name'),
          key: 'name',
          width:180,
          render: (h, params) => {
            return h('span',{
              style:{
                color:'#5578F6',
                cursor:'pointer'
              },
              on: {
                click: () => {
                  this.$router.push({
                    path:'/dataset/private-data',
                    query:{
                      id:params.row.id,
                    }
                  })
                }
              }
            }, params.row.name);
          }

        },
        {
          title: this.$t('dataset_category_table_data_count'),
          key: 'data_count',
        },
        {
          title: '状态',
          key: 'desc',
          render: (h, para) => {
            return h('span', this.statusObj[para.row.status]);
          }
        },
        // {
        //   title: '类型',
        //   key: 'type',
        //   render: (h, para) => {
        //     return h('span', this.typeObj[para.row.type]);
        //   }
        // },
        // {
        //   title: '关联知识图谱',
        //   key: 'type',
        //   render: (h, para) => {
        //     return h('span', para.row.map ? para.row.map['name'] : '');
        //   }
        // },
        // {
        //   title: '自动开通',
        //   key: 'type',
        //   render: (h, para) => {
        //     return h('span', this.autoOpenObj[para.row.auto_open]);
        //   }
        // },
        // {
        //   title: '免费刷题',
        //   key: 'type',
        //   render: (h, para) => {
        //     return h('span', this.isfreeObj[para.row.is_free]);
        //   }
        // },
        {
          title: '排序',
          key: 'sort',
        },
        // {
        //   title: this.$t('dataset_category_table_desc'),
        //   key: 'desc',
        // },
        {
          title: this.$t('dataset_data_table_created_at'),
          key: 'created_at',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.created_at * 1000), 'MM-dd hh:mm'
                )
            );
          }
        },
        {
          title: this.$t('dataset_data_table_updated_at'),
          key: 'updated_at',
          render: (h, para) => {
            return h('span', util.timeFormatter(
                    new Date(+para.row.updated_at * 1000), 'MM-dd hh:mm'
                )
            );
          }
        },
        // {
        //   title: '考点提取和解析',
        //   //key: 'updated_at',
        //   render: (h, para) => {
        //     return  h('div', [
        //       para.row.generate && h('span', this.generateStatusesObj[para.row.generate.status]),
        //       !para.row.generate && h('Button', {
        //         props: {
        //           type: 'primary',
        //           size: 'small'
        //         },
        //         style: {
        //           marginRight: '5px'
        //         },
        //         on: {
        //           click: () => {
        //             this.generate(para.row);
        //           }
        //         }
        //       }, '生成'),
        //
        //     ]);
        //   }
        // },
        {
          title: this.$t('dataset_category_table_op'),
          width:160,
          render: (h, params) => {
            return h('div', [
              // h('Button', {
              //   props: {
              //     type: 'primary',
              //     size: 'small'
              //   },
              //   style: {
              //     marginRight: '5px'
              //   },
              //   on: {
              //     click: () => {
              //       // this.edit(params.row)
              //       this.modalTit = '添加分类';
              //       this.type = '';
              //       this.formItem = {
              //         id:'',
              //         name:'',
              //         desc:'',
              //         statusId: '1',
              //         sort: '',
              //       };
              //       this.formItem.parentId = params.row.id;
              //       this.formItem.parentName = params.row.name;
              //       this.modalStatus = true;
              //     }
              //   }
              // }, '添加分类'),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.edit(params.row)
                  }
                }
              }, this.$t('system_edit')),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    // this.edit(params.row)
                    this.curData = params.row;
                    this.addData()
                  }
                }
              }, '单题添加'),
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    // this.edit(params.row)
                    this.$router.push({
                      path:'/manager/trainingcamp/mygroup-create',
                      query:{
                        id:params.row.id,
                      }
                    })
                  }
                }
              }, '导入试题'),
              h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.delete(params.row);
                  }
                }
              }, this.$t('system_dele')),
            ]);
          }
        }
      ],
      data: [],
      loading:false,
      deleteComfirmModal:true,
      deleModalStatus:false,
      modalLoading:false,
      curDeleData:{},
      page:1,
      pageSize:10,
      total:0,
      modalStatus:false,
      formItem:{
        firstCategoryId:'',
        id:'',
        name:'',
        desc:'',
        statusId:'1',
        sort:'',
        parentName:'',
        parentId:'',
        site_id:0,
        user_id:0,
        isfree:'1',
        autoOpen:'0',
        //mapId:'',
        type:''
      },
      ruleValidate: {
        firstCategoryId:[
          { required: true, message: '请选择一级分类', trigger: 'change' }
        ],
        parentId:[
          { required: true, message: '请选择二级分类', trigger: 'change' }
        ],
        name: [
          { required: true, message: this.$t('dataset_category_add_name_tip'), trigger: 'blur' }
        ],
        statusId:[
          { required: true, message: '请选择状态', trigger: 'blur' }
        ],
        desc: [
          { required: false, message: this.$t('dataset_category_add_desc_tip'), trigger: 'blur' },
        ],
        isfree: [
          { message: this.$t('dataset_category_add_desc_tip'),validator: validateIssfrees, trigger: 'blur' },
        ],
        autoOpen: [
          { message: this.$t('dataset_category_add_desc_tip'),validator: validateIssfrees, trigger: 'blur' },
        ],
      },
      addBtnLoading:false,
      type:'',
      modalTit:'',
      curData:{},
      trainSetting:false,
      categoryList:[],
      curCategoryId:'-1',
      secondCategoryList:[],
      cursecondCategoryId:'-1',
      statusObj:{},
      statusList:[],
      typeId:'0',
      typeObj:{},
      isfreeObj:{},
      autoOpenObj:{},
      keyword:'',
      generateStatusesObj:{},
      formCategoryList:[],
      formSecondCategoryList:[],
      newData:{},
      addDataModal:false,
    }
  },
  components:{
    DeleModal,
    TrainSeting,
    AddData
  },
  mounted(){
    this.getCategoryList();
  },
  methods:{
    changeKeyword(){
      this.page = 1;
      this.getList();
    },
    toCategoryVerify(){
      this.$router.push({
        path:'/dataset/category-verify',
      })
    },
    toAiGenerateList(){
      this.$router.push({
        path:'/dataset/map-aigenerate-list',
        query:{

        }
      })
    },

    changeSearch(data,name){
      this[name] = data.id;
      this.page = 1;
      if(name == 'curCategoryId'){
        this.getSecondCategoryList();
      }else if(name == 'cursecondCategoryId'){
        this.getThirdCategoryList();
      }
    },
    add(){
      this.modalTit = this.$t('dataset_category_add');
      this.type = '';
      this.formItem = {
        id:'',
        name:'',
        desc:'',
        statusId: '1',
        sort: '',
        isfree:'1',
        autoOpen:'0',
      };
      this.formItem.parentId = 0;
      this.modalStatus = true;
    },
    // check(data){
    //   this.modalTit = this.$t('dataset_category_check');
    //   this.type = 'check';
    //   this.formItem = {
    //     id:data.id,
    //     name:data.name,
    //     data_count:data.data_count,
    //     desc:data.desc
    //   };
    //   this.modalStatus = true;
    // },
    edit(data){
      this.modalTit = this.$t('dataset_category_edit');
      this.type = 'edit';
      this.formItem = {
        site_id:-1,
        user_id:-1,
        id:data.id,
        name:data.name,
        desc:data.desc,
        statusId: data.status,
        parentName: data.parent ? data.parent.name : '',
        parentId: data.parent_id,
        sort: data.sort,
        type:data.type,
        mapId: data.map_id,
        isfree: data.is_free,
        autoOpen: data.auto_open,
      };
      this.modalStatus = true;
    },
    getCategoryList(){
      let data = {
        page:1,
        pageSize:10,
        // category_id:this.curCategoryId,
        // category_second_id:this.cursecondCategoryId,
        user_id:-1,
        type:4,
      };

      this.api.dataset.categoryForm(data).then((res)=>{

        //一级标签
        this.categoryList = [
          {
            name:'全部',
            id:'-1'
          },
          ...res.categoryFirstList
        ];
        this.formCategoryList = [
          ...res.categoryFirstList
        ];
        this.curCategoryId = this.categoryList[0].id;

        this.statusObj = res.statuses;
        this.statusList = [];
        for(let name in res.statuses){
          this.statusList.push({
            name:res.statuses[name],
            id:name
          })
        }
        this.isfreeObj = res.isfrees;
        this.autoOpenObj = res.autoOpens;
        this.getSecondCategoryList();
      })
    },
    getSecondCategoryList(){
      let data = {
        page:1,
        pageSize:10,
        category_id:this.curCategoryId,
        // category_second_id:this.cursecondCategoryId,
        user_id:-1,
        type:4,
      };

      this.api.dataset.categoryForm(data).then((res)=>{

        // 二级标签
        this.secondCategoryList = [
          {
            name:'全部',
            id:'-1'
          },
          ...res.categorySecondList
        ];
        this.cursecondCategoryId = this.secondCategoryList[0].id;
        this.getThirdCategoryList();
        // console.log(this.secondCategoryList,'this.secondCategoryList')
        // this.thirdCategoryList = [
        //   {
        //     name:'全部',
        //     id:'-1'
        //   },
        //   ...res.categoryThirdList
        // ]
      })
    },
    changeFirstCategory(){
      this.getFormSecondCategoryList();
    },
    getFormSecondCategoryList(){
      let data = {
        page:1,
        pageSize:10,
        category_id:this.formItem.firstCategoryId,
        // category_second_id:this.cursecondCategoryId,
        user_id:-1,
        type:4,
      };

      this.api.dataset.categoryForm(data).then((res)=>{

        // 二级标签
        this.formSecondCategoryList = [
          ...res.categorySecondList
        ];
      })
    },
    getThirdCategoryList(){
      let data = {
        page:1,
        pageSize:10,
        // category_id:this.cursecondCategoryId,
        // category_second_id:this.cursecondCategoryId,
        user_id:-1,
      };
      if(this.cursecondCategoryId > -1){
        data.category_id = this.cursecondCategoryId
      }else{
        data.category_id = this.curCategoryId
      }

      this.loading = true;
      this.api.course.selfThirdList(data).then((res)=>{
        this.loading = false;
        this.data = res.list;
      }).catch((err)=>{
        this.loading = false;
      })
    },
    delete(data){
      this.curDeleData = data;
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let data = {
        site_id:-1,
        user_id:-1,
        id:this.curDeleData.id,
      }
      this.modalLoading = true;
      this.api.dataset.categoryDelete(data).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getCategoryList();
        this.$emit("refresh");
      }).catch((err)=>{
        this.modalLoading = false;
      });
    },
    // changePage(page){
    //   console.log(page,'changePage')
    //   this.page = page;
    //   this.getList();
    // },
    // changePageSize(pageSize){
    //   this.pageSize = pageSize;
    //   this.page = 1;
    //   this.getList();
    // },
    ok(){
      this.$refs.formCustom.validate((valid) => {
        if (valid) {
          let data = {
            site_id:this.formItem.site_id,
            user_id:this.formItem.user_id,
            id:this.formItem.id,
            name:this.formItem.name,
            desc:this.formItem.desc,
            parent_id:this.formItem.parentId,
            status:this.formItem.statusId,
            sort:this.formItem.sort,
            type:4,
            map_id:this.formItem.mapId,
            is_free:this.formItem.isfree,
            auto_open:this.formItem.autoOpen,
            is_private:1,
          };
          this.addBtnLoading = true;
          if(this.formItem.id){
            this.api.dataset.categoryUpdate(data).then((res)=>{
              this.addBtnLoading = false;
              this.modalStatus = false;
              this.getThirdCategoryList();
              this.$emit("refresh");
            }).catch((err)=>{
              this.addBtnLoading = false;
            })
          }else{
            this.api.dataset.categoryCreate(data).then((res)=>{
              this.addBtnLoading = false;
              this.modalStatus = false;
              this.page = 1;
              this.getThirdCategoryList();
              this.$emit("refresh");
            }).catch((err)=>{
              this.addBtnLoading = false;
            })
          }

        }else{
          console.log(this.modalStatus)
        }
      })
    },
    cancel(){
      this.modalStatus = false;
      this.$refs.formCustom.resetFields();
    },

    confirmBrushConfig(data){ //刷题设置确认
      let params = {
        action:'userSetting',
        product_id:2, //活动 ID 每日一练:2
        ...data
      };
      this.api.dataset.trainExecute(params).then((res)=>{
        this.trainSetting = false;
        this.$router.push({
          path:'/trainingcamp/trainDetail',
          query:{
            id:this.curData.id,
            dataSort:'0',
          }
        })
      }).catch((err)=>{
        this.trainSetting = false;
      })
    },
    closeTrainSetting(){
      this.trainSetting = false;
    },
    confrimTrainDetail(data,dataSort){ //0顺序1随机4每日一练
      this.$router.push({
        path:'/trainingcamp/trainDetail',
        query:{
          id:this.curData.id,
          dataSort:dataSort,
        }
      })
    },
    generate(data){ //刷题设置确认
      let params = {
        category_id:data.id,
      };
      this.api.dataset.categoryGenerateStart(params).then((res)=>{
        this.getList();
      }).catch((err)=>{
      })
    },
    changeData(data){
      this.newData = JSON.parse(JSON.stringify(data));
    },
    confirmCorrection(){  //确认纠错
      if(!this.newData.name){  //标题
        this.$Message.warning(this.$t('trainingcamp_train_warning_tit'));
        return;
      }
      if(this.newData.type == '1' || this.newData.type == '2' || this.newData.type == '3'){
        if(!this.newData.options.length){ //选项
          this.$Message.warning(this.$t('trainingcamp_train_warning_add_option'));
          return;
        }
        let len = this.newData.options.filter((item)=>{
          return item.is_correct == '1';
        }).length

        if (!len && this.newData.type != '4') { //正确选项
          this.$Message.warning(this.$t('trainingcamp_train_warning_select'));
          return;
        }
        for(let i=0;i<this.newData.options.length;i++){
          if(!this.newData.options[i].name){
            this.$Message.warning(this.$t('trainingcamp_train_warning_input_cont'));
            return;
            break;
          }
        }
        if(this.newData.type == '1' || this.newData.type == '3'){
          if(len > 1){
            this.$Message.warning('只能选择一个正确答案');
            return;
          }
        }
      }


      if(!this.newData.labelKeywords.length){
        this.$Message.warning('请选择标签');
        return;
      }


      // if(!this.correctionInfo.datasetData.explain){ //解析
      //   this.$Message.warning('请输入解析');
      //   return;
      // }
      // let type = this.typesList.filter((item)=>{
      //   return item.name == this.questionType
      // })[0].value;
      if(this.newData.type == 4){
        this.newData.options.forEach((item)=>{
          item.is_correct = '1';
        })
      }

      let params = {
        name:this.newData.name,
        explain:this.newData.explain,
        options:JSON.stringify(this.newData.options),
        type:this.newData.type,
        level:this.newData.level,
        status:this.newData.status,
        source:this.newData.source,
        label_keyword_ids:this.newData.labelKeywords.join(','),
        label_keypoint_ids:this.newData.labelKeypoints.join(','),
        category_id:this.curData.id,
      };

      this.api.dataset.dataCreate(params).then((res)=>{
        this.addDataModal = false;
        this.$Message.success('添加成功');
        // this.getInfo()
        this.getThirdCategoryList();
      })
    },
    addData(){
      this.addDataModal = true;
    }
  }
}
</script>

<style scoped lang="scss">
.role-main{
  .search{
    margin-top: 20px;
    margin-bottom: 20px;
    .search-item{
      margin-bottom: 15px;
      display: flex;
      justify-content: flex-start;
      font-size: 14px;

      .name{
        padding: 5px 0;
        width: 75px;
        text-align: right;
      }
      .cont{
        margin-left: 15px;
        flex: 1;
        display: flex;
        justify-content: space-between;

        .values{
          display: flex;
          justify-content: flex-start;
          flex-flow: wrap;
          transition: max-height 0.3s linear;
          overflow: hidden;

          >div{
            padding: 5px 10px;
            //margin: 0 10px 10px 5px;
            cursor: pointer;
          }
          >div.active{
            background-color: #2d8cf0;
            color:#FFFFFF;
            border-radius: 4px;
          }
        }
        .more{
          padding: 5px 0;
          width: 80px;
          text-align: right;
          cursor: pointer;
          .more-icon{
            transition: transform 0.3s linear;
          }
        }
      }
    }
  }
  .top{
    text-align: left;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .page{
    margin-top: 20px;
    text-align: right;
  }
}
.brush-question{
  .tit{
    font-size: 16px;
    font-weight: bold;
  }
  .list{
    margin: 20px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    >span{
      margin-right: 10px;
      padding: 5px 20px;
      display: inline-block;
      background-color: #e8eaec;
      border-radius: 4px;
      cursor: pointer;
    }
    >span.active{
      border: 1px solid #2d8cf0;
      background-color: #2d8cf0;
      color:#FFFFFF;
    }
  }
}
</style>
