<template>
  <div class="course">
    <div class="course-nr">
      <div class="top-tip mt20">
        提示：课程管理分为组织课程和平台课程；组织课程为组织自行创建的课程，可对其进行增删改查操作；平台课程为公有资源，只可对其分配权限及上下架操作。
      </div>
      <div class="guide-img mt20">
        <img src="../../../assets/images/new_icon/kecyd.png" width="100%" />
      </div>
      <div class="table" v-if="dataList.length">
        <Table border :columns="columns" :loading="loading" :data="dataList"></Table>
      </div>
      <div class="page" v-if="dataList.length">
        <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>
      </div>
      <div v-else class="no-data">
        <img src="../../../assets/images/new_icon/nodata.png" width="158" height="82" />
        <p>还没有课程哦，点击“新增课程”添加，或联系平台进行平台课程开通</p>
      </div>
    </div>

    <!-- 编辑抽屉 -->
    <el-drawer :visible.sync="drawerShow" :size="1200" @close="drwaerColse()">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">{{ drawerTitle }}</div>
        <div class="drawer-title-btns">
          <Button class="mr10" size="large" @click="drwaerColse()">取消</Button>
          <Button type="primary" size="large" @click="confirm">确定</Button>
        </div>
      </div>
      <div style="padding: 20px;">
        <div class="course-create-nr">
          <el-form ref="formValidate" :model="formValidate" :rules="ruleValidate" label-width="110px">
            <div class="distinguish" v-if="!isPrivate">
              <div class="distinguish-color"></div>
              <div class="distinguish-text">可编辑</div>
            </div>
            <div class="distinguish" v-if="!isPrivate">
              <div class="distinguish-color back-red"></div>
              <div class="distinguish-text">不可编辑</div>
            </div>
            <el-form-item label="方向" prop="category_id">
              <el-select v-model="formValidate.category_id" v-if="isPrivate" :disabled="!isPrivate || !allowEditCategory"
                placeholder="请选择方向" class="w300">
                <el-option v-for="item in categoryList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
              <el-input v-else v-model="categoryName" disabled size="large" placeholder="请输入名称" class="w300"></el-input>
              <span v-if="isPrivate" style="padding-left: 20px">选择课程所属分类，还没有分类？<a @click="goCategory"
                  style="color: #4a79ff">点击去创建</a></span>
            </el-form-item>
            <el-form-item label="名称" prop="name">
              <el-input v-model="formValidate.name" maxlength="20" :disabled="!isPrivate" size="large" placeholder="请输入名称"
                class="w300"></el-input>
            </el-form-item>
            <el-form-item label="等级" prop="lesson_level">
              <el-select v-model="formValidate.lesson_level" :disabled="!isPrivate" placeholder="请选择等级" class="w300">
                <el-option v-for="item in levelList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="课时数" prop="lesson_count">
              <el-input-number :disabled="!isPrivate" v-model="formValidate.lesson_count" size="large"
                placeholder="请输入课时数" style="width: 180px"></el-input-number>
            </el-form-item>
            <el-form-item label="周课数">
              <el-input-number type="number" :disabled="!isPrivate" v-model="formValidate.lesson_week_count" size="large"
                placeholder="请输入周课数" style="width: 180px"></el-input-number>
            </el-form-item>
            <el-form-item style="width: 50%" label="课程描述" prop="content">
              <el-input type="textarea" :disabled="!isPrivate" v-model="formValidate.content" :rows="5" style="width: 70%"
                size="large" placeholder="请输入课程描述"></el-input>
            </el-form-item>
            <el-form-item label="封面图" prop="uploadImg" class="w300">
              <el-upload  :action="constant.URL + '/uploadfile/upload'" :data="{
                type: 3,
              }" accept=".jpg,.png,.jpeg,.gif" :headers="{
  'access-token': Cookies.get(constant.tokenName),
}" :on-success="uploadFileSuccess" :show-file-list="false" :disabled="!isPrivate">
                <div class="upload-btn" v-if="!formValidate.uploadImg">
                  <Icon type="ios-add" :size="50" />
                </div>
                <img :src="formValidate.uploadImg" width="120" v-else />
              </el-upload>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </el-drawer>

    <!-- 班级配置抽屉 -->
    <el-drawer :visible.sync="configDrawer" :size="1200" @close="drwaerColse()">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">{{ drawerTitle }}</div>
        <div class="drawer-title-btns">
          <Button class="mr10" size="large" @click="drwaerColse()">取消</Button>
          <Button type="primary" size="large" @click="confirm">确定</Button>
        </div>
      </div>
      <div style="padding: 20px;">
        <el-form ref="formValidate" :model="formValidate" :rules="ruleValidate" label-width="110px">
          <el-form-item label="班级查看权限" prop="group_ids">
            <el-select class="classAuth" v-model="formValidate.group_ids" multiple placeholder="请选择班级" style="width: 100%;">
              <el-option v-for="item in groupList" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
<!--    选择分类-->
    <el-drawer :visible.sync="selectCategoryDrawer" :size="1200">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">请选择新课程所属分类</div>
      </div>
      <div style="padding: 20px;">
        <p v-if="!allCategoryList.length">您当前没有可供选择的分类，请先<span style="color:#4A79FF;cursor: pointer" @click="goCourseCategory">创建分类</span>。</p>
        <el-table
            v-else
            :data="allCategoryList"
            border
            style="width: 100%">
          <el-table-column
              prop="id"
              label="ID">
          </el-table-column>
          <el-table-column
              prop="name"
              label="分类">
          </el-table-column>
          <el-table-column
              label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="selectCategory(scope.row)">选择</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-drawer>
    <DeleModal :status="deleModalStatus" txt="确认删除吗？" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>
  </div>
</template>

<script>

import util from '@/utils/tools.js';
import Cookies from "js-cookie";
import DeleModal from '@/components/deleteModal.vue';
export default {
  name: "manageView",
  props:{
    refresh:{
      type:Number,
      default:0
    }
  },
  components:{
    DeleModal
  },
  data() {
    return {
      Cookies,
      columns: [
        {
          title: '课程名称',
          key: 'name',
          tooltip:true,
        },
        {
          title: '类型',
          key: 'name',
          render: (h, params) => {
            return h('span', params.row.is_private == 1 ? '组织课程' : '平台课程');
          }
        },
        {
          title: '课时数',
          key: 'lesson_count',
          render: (h, params) => {
            return h('span', params.row.lesson_count);
          }
        },
        {
          title: '等级',
          key: 'id',
          render: (h, params) => {
            return h('span', this.lesson_levels[params.row.lesson_level]);
          }
        },
        // {
        //   title: '班级查看',
        //   key: 'group',
        //   // ellipsis:true,
        //   tooltip: true,
        //   // render:(h,params)=>{
        //   //   let names = params.row.group.map((item)=>{
        //   //     return item.group.name;
        //   //   }).join(',');
        //   //   return h('span', names);
        //   // }
        //   render: (h, params) => {
        //     let texts = '';
        //     let names = params.row.group.map((item) => {
        //       return item.group.name;
        //     }).join(',');
        //     if (names != null) {
        //       if (names.length > 20) {
        //         texts = names.substring(0, 20) + '...' // 进行数字截取或slice截取超过长度时以...表示
        //       } else {
        //         texts = names
        //       }
        //     }
        //     return h('Tooltip', {
        //       props: {
        //         placement: 'bottom-start',
        //         transfer: true
        //       },
        //     }, [texts,
        //       h('span', {
        //         slot: 'content',
        //         style: {
        //           whiteSpace: 'normal',
        //           wordBreak: 'break-all'
        //         }
        //       }, names)
        //     ])
        //   }
        // },
        {
          title: '课程方向',
          key: 'id',
          render: (h, params) => {
            return h('span', params.row.category ? params.row.category.name : '');
          }
        },
        {
          title: '状态',
          key: 'id',
          render: (h, params) => {
            return h('span', this.statuses[params.row.status]);
          }
        },
        {
          title: '更新时间',
          key: 'id',
          render: (h, params) => {
            // return h('span', util.timeFormatter(new Date((+params.row.created_at || +params.row.updated_at) * 1000), 'yyyy-MM-dd hh:mm'));
            return util.tableColumnTooltip(h,util.timeFormatter(new Date((+params.row.created_at || +params.row.updated_at) * 1000), 'yyyy-MM-dd hh:mm'));
          }
        },
        {
          title: '操作',
          key: 'id',
          width: 310,
          render: (h, params) => {
            return h('div', [
              h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.goDetail(params.row)
                  }
                }
              }, '查看资源'),
              params.row.is_private == '1' ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style: {
                  marginRight: '5px'
                },
                on: {
                  click: () => {
                    this.goMap(params.row)
                  }
                }
              }, '图谱设置') : '',
              params.row.is_private == '1'?h('Button', {
                props: {
                  type: 'warning',
                  size: 'small'
                },
                style:{
                  marginRight:"5px"
                },
                on: {
                  click: () => {
                    this.edit(params.row)
                  }
                }
              }, '编辑'):"",
              params.row.status == 0 ? h('Button', {
                props: {
                  // type: 'info',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.offLine(params.row);
                  }
                }
              }, '下架') : h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                on: {
                  click: () => {
                    this.onLine(params.row);
                  }
                }
              }, '上架'),
              params.row.is_private == '1'?h('Button', {
                props: {
                  type: 'error',
                  size: 'small'
                },
                style:{
                  marginLeft:"5px"
                },
                on: {
                  click: () => {
                    this.delete(params.row)
                  }
                }
              }, '删除'):"",
              params.row.is_private != '1' ? h('Button', {
                props: {
                  type: 'primary',
                  size: 'small'
                },
                style:{
                  marginLeft:"5px"
                },
                on: {
                  click: () => {
                    this.copy(params.row)
                  }
                }
              }, '复制') : '',
              // h("Button", {
              //   props: {
              //     type: "text",
              //     size: "small"
              //   },
              //   on: {
              //     click: () => {
              //       this.classConfig(params.row)
              //     }
              //   }
              // }, "班级配置")
            ]);
          }
        },
      ],
      dataList: [],
      loading: false,
      page: 1,
      pageSize: 10,
      total: 0,
      courseModal: false,

      statuses: {},
      curData: {},
      lesson_levels: {},
      drawerShow: false,
      formValidate: {
        name: "",
        uploadfile_id: "",
        lesson_level: "",
        lesson_count: "",
        lesson_week_count: '',
        group_ids: [],
        category_id: "",
        content: "",
        uploadImg: "",
      },
      ruleValidate: {
        name: [{ required: true, message: "请输入名称", trigger: "blur" }],
        category_id: [
          { required: true, message: "请选择方向", trigger: "change" },
        ],
        lesson_level: [
          { required: true, message: "请输入等级", trigger: "blur" },
        ],
        group_ids: [
          { required: true, message: "请选择班级", trigger: "change" },
        ],
        lesson_count: [
          { required: true, message: "请输入课时数", trigger: "blur" },
        ],
        uploadImg: [
          { required: true, message: "请上传封面", trigger: "change" },
        ],
        content: [
          { required: true, message: "请输入课程描述", trigger: "blur" },
        ],
      },
      uploadImg: "",
      categoryList: [],
      optionProps: {
        value: "child_id",
        label: "name",
        children: "children",
        // checkStrictly: true
      },
      groupList: [],
      dataId: "",
      uploadfileIdRequire: true,
      isPrivate: true,
      categoryName: "",
      levelList: [],
      allowEditCategory: true,
      drawerTitle: "",
      configDrawer: false,
      deleModalStatus:false,
      modalLoading:false,
      selectCategoryDrawer:false,
      allCategoryList:[],
    }
  },
  created() {
    this.getList();
    this.getForm();
    this.getCategory();
    this.getGroupList();

  },
  methods: {
    // 班级配置
    classConfig(data) {
      this.drawerTitle = "编辑课程-"+data.name;
      this.dataId = data.id;
      if (this.dataId) {
        this.getDetail(data);
      }
      this.isPrivate = !data.is_private || data.is_private == "1" ? true : false;
      this.configDrawer = true;
    },
    // 获取班级详情
    getDetail(data) {
      let params = {
        id: data.id
      };
      this.api.course.courseDetail(params).then((res) => {
        this.formValidate.name = res.info.name;
        this.formValidate.lesson_level = res.info.lesson_level;
        this.formValidate.lesson_count = res.info.lesson_count;
        this.formValidate.category_id = res.info.category.id;
        this.formValidate.group_ids = res.info.group_list.map((item) => {
          return item.group_id;
        });
        this.formValidate.uploadfile_id = "";
        this.formValidate.uploadImg = res.info.map.book_img;
        this.formValidate.content = res.info.content;
        this.formValidate.lesson_week_count = res.info.lesson_week_count;
        this.categoryName = res.info.category.name;
        this.allowEditCategory = res.allow_edit_category == '1';
      });
    },
    getForm() {
      this.api.course.courseForm().then((res) => {
        this.levelList = [];
        for (let name in res.lesson_levels) {
          this.levelList.push({
            name: res.lesson_levels[name],
            id: name,
          });
        }
      });
    },
    getCategory() {
      this.api.course.selfThirdList({
        user_id:'-1'
      }).then((res) => {
        this.categoryList = res.list;
      });
    },
    getAllCategory() {
      this.api.course.selfThirdList({
        user_id:'-1'
      }).then((res) => {
        this.allCategoryList = res.list;
      });
    },
    getGroupList() {
      let params = {
        user_id: "-1",
        pageSize: 100,
      };
      this.api.user.groupList(params).then((res) => {
        this.groupList = res.list;
      });
    },
    uploadFileSuccess(response) {
      //上传文件成功
      this.formValidate.uploadfile_id = response.data.info.id;
      this.formValidate.uploadImg = response.data.info.upload_path;
      this.$refs.formValidate.validateField("uploadfile_id");
    },
    confirm() {
      this.$refs["formValidate"].validate((valid) => {
        if (valid) {
          let params = {
            name: this.formValidate.name,
            lesson_level: this.formValidate.lesson_level,
            lesson_count: this.formValidate.lesson_count,
            category_id: this.formValidate.category_id,
            group_ids: this.formValidate.group_ids,
            uploadfile_id: this.formValidate.uploadfile_id,
            content: this.formValidate.content,
            lesson_week_count: this.formValidate.lesson_week_count
          };
          if (this.dataId) {
            //编辑
            params.id = this.dataId;
            this.api.course.courseUpdate(params).then((res) => {
              this.$Message.success("操作成功");
              // this.courseModal = false;
              this.drawerShow = false;
              this.configDrawer=false;
              this.getList();
            });
          } else {
            this.api.course.courseCreate(params).then((res) => {
              this.$Message.success("操作成功");
              // this.courseModal = false;
              this.drawerShow = false;
              this.configDrawer=false;
              this.getList();
            });
          }
        }
      });
    },
    goCategory() {
      this.$router.push({
        path: "/course/category",
      });
    },

    changePage(page) {
      this.page = page;
      this.getList();
    },
    changePageSize(pageSize) {
      this.pageSize = pageSize;
      this.page = 1;
      this.getList();
    },
    getList() {
      let params = {
        page: this.page,
        pageSize: this.pageSize,
        user_id:'-1',
      };
      this.api.course.courseList(params).then((res) => {
        this.dataList = res.list;
        this.total = Number(res.count);
        this.statuses = res.statuses;
        this.lesson_levels = res.lesson_levels;
      })
    },
    add() {
      // this.$router.push({
      //   path:'/course/create'
      // })
      this.drawerTitle = "添加";
      this.dataId = "";
      this.drawerShow = true;
    },
    edit(data) {
      console.log(data);
      this.drawerTitle = "编辑课程-"+data.name;
      this.dataId = data.id;
      if (this.dataId) {
        this.getDetail(data);
      }
      this.isPrivate = !data.is_private || data.is_private == "1" ? true : false;
      this.drawerShow = true;
      // this.$router.push({
      //   path:'/course/create',
      //   query:{
      //     id:data.id,
      //     isPrivate:data.is_private
      //   }
      // })
    },
    drwaerColse() {
      this.formValidate = {
        name: "",
        uploadfile_id: "",
        lesson_level: "",
        lesson_count: "",
        lesson_week_count: '',
        group_ids: [],
        category_id: "",
        content: "",
        uploadImg: "",
      }
      if(this.$refs.formValidate){
        this.$refs.formValidate.resetFields();
      }
      this.drawerShow = false;
      this.configDrawer=false;
    },
    onLine(data) {
      let params = {
        id: data.id
      };
      this.api.course.courseOnline(params).then((res) => {
        this.$Message.success('操作成功')
        this.getList();
      })
    },
    offLine(data) {
      let params = {
        id: data.id
      };
      this.api.course.courseOffline(params).then((res) => {
        this.$Message.success('操作成功')
        this.getList();
      })
    },
    goDetail(data) {
      this.$router.push({
        path: '/course/detail',
        query: {
          id: data.id,
          mapId: data.map_id,
          isPrivate: data.is_private
        }
      })
    },
    goMap(data) {
      this.$router.push({
        path: '/teacher/dataset/mapCreate',
        query: {
          id: data.map_id,
          // categoryId:data.category_id
        }
      })
    },
    delete(data){
      if(data.status == 0){
        this.$Message.warning('请下架课程后进行删除');
        return;
      }
      this.dataId = data.id;
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let data = {
        id:this.dataId,
      }
      this.modalLoading = true;
      this.api.course.courseDelete(data).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      });

    },
    selectCategory(data){ //选择分类复制课程
      let params = {
        category_id:data.id,
        source_course_id:this.curData.id
      }
      this.api.course.courseCopy(params).then((res)=>{
        this.$message.success('复制成功');
        this.selectCategoryDrawer = false;
        this.page = 1;
        this.getList();
      })
    },
    copy(data){
      this.curData = data;
      this.selectCategoryDrawer = true;
      this.getAllCategory();
    },
    goCourseCategory(){
      this.selectCategoryDrawer = false;
      this.$router.push({
        path:'/course/teacher-list',
        query:{
          tab:'courseCategory'
        }
      })
    }
  },
  watch:{
    refresh(newVal){
      this.getList();
      this.getForm();
      this.getCategory();
      this.getGroupList();
    }
  }
}
</script>

<style scoped lang="scss">
.course {

  .course-nr {
    border-radius: 4px;

    .train-tab {
      margin-bottom: 20px;

      // border-bottom: 1px solid #EEEEEE;
      font-size: 16px;
      color: #666666;
      display: flex;
      justify-content: space-between;
      align-items: center;
      >p {
        padding-bottom: 10px;
      }

      .active {
        font-weight: bold;
        color: #4A79FF;
        border-bottom: 4px solid #5782FF;
      }
    }

    .table {
      margin: 20px 0;
    }

    .page {
      text-align: right;
    }

    .guide-img {
      padding: 20px;
      background: #F8F8F9;
      border-radius: 4px;
    }
  }
}

.no-data {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  >p {
    margin-top: 35px;
    font-size: 14px;
    font-weight: 400;
    color: #666666;
  }
}
::v-deep .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
</style>

