<template>
  <Modal
      v-model="status"
      :title="$t('trainingcamp_train_seeting_tit')"
      @on-cancel="cancel"
  >
    <div class="brush-question">
      <p class="tit">{{$t('trainingcamp_train_seeting_single')}}</p>
      <div class="list">
        <span :class="curCount == item.count ? 'active' : ''" v-for="item in countList" :key="item.count" @click="changeCount(item)">{{item.count}}</span>
      </div>
      <p class="tit">{{ $t('trainingcamp_train_seeting_source') }}</p>
      <div class="list">
        <span :class="curSourceId == item.id ? 'active' : ''" v-for="item in sourceList" :key="item.id" @click="changeSource(item)">{{ item.name }}</span>
      </div>
    </div>
    <div slot="footer">
      <Button @click="cancel">{{ $t('dataset_cancel') }}</Button>
      <Button type="primary" @click="confirm">{{ $t('dataset_confirm') }}</Button>
    </div>
  </Modal>
</template>

<script>
export default {
  name: "trainSetting.vue",
  data(){
    return {
      brushQuestion:true,
      sourceList:[
        {
          name:this.$t('trainingcamp_train_seeting_new_error'),
          id:1
        },
        {
          name:this.$t('trainingcamp_train_seeting_new'),
          id:2
        },
        {
          name:this.$t('trainingcamp_train_seeting_error'),
          id:3
        },
        {
          name:this.$t('trainingcamp_train_seeting_unlimited'),
          id:0
        }
      ],
      curSourceId:2,
      countList:[
        {
          count:5
        },
        {
          count:10
        },
        {
          count:20
        },
        {
          count:30
        },
        {
          count:50
        }
      ],
      curCount:10,
    }
  },
  props:{
    status:{
      type:Boolean,
      default:false,
    }
  },
  methods:{
    confirm(){
      this.$emit('confirm',{count:this.curCount,data_from:this.curSourceId})
    },
    cancel(){
      this.$emit('close')
    },
    changeSource(data){
      this.curSourceId = data.id;
    },
    changeCount(data){
      this.curCount = data.count;
    },
  }
}
</script>

<style scoped lang="scss">
.brush-question{
  .tit{
    font-size: 16px;
    font-weight: bold;
  }
  .list{
    margin: 20px 0;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    >span{
      margin-right: 10px;
      padding: 5px 20px;
      display: inline-block;
      background-color: #e8eaec;
      border-radius: 4px;
      cursor: pointer;
    }
    >span.active{
      border: 1px solid #2d8cf0;
      background-color: #2d8cf0;
      color:#FFFFFF;
    }
  }
}
</style>
